<ion-page>
  <app-header title="Projects"></app-header>
  <ion-content :fullscreen="true">
    <ion-grid>
      <ion-row>
        <ion-col size="1">
          <ion-button
            color="primary"
            @click="addProject"
            v-if="isAuthenticated"
          >
            <ion-icon :icon="add" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col size="8" size-md="4" offset-md="3">
          <viewer-selector
            :value="viewingType"
            @viewSelected="changeView"
          ></viewer-selector>
        </ion-col>
        <ion-col size="1" offset="1" offset-md="3">
          <app-filter
            filterTitle="Filter by Language"
            :filterOptions="Language"
            @filters="onFilter"
          ></app-filter>
        </ion-col>
      </ion-row>
      <ion-row v-if="viewingType === 'table'">
        <ion-col size-md="10" offset-md="1">
          <app-table
            :columns="columns"
            :data="filteredData"
            @editEntry="editProject"
            @deleteEntry="onDeleteProject"
          ></app-table>
        </ion-col>
      </ion-row>
      <ion-row v-if="viewingType === 'grid'">
        <ion-col>
          <app-grid
            :cardData="cardData"
            :cardSize="3"
            :data="filteredData"
            :withDetail="true"
            @editEntry="editProject"
            @deleteEntry="onDeleteProject"
            @goToDetail="goToProjectDetail"
          ></app-grid>
        </ion-col>
      </ion-row>
      <ion-row v-if="viewingType === 'slides'">
        <ion-col>
          <app-slides
            :cardData="cardData"
            :data="filteredData"
            :withDetail="true"
            @editEntry="editProject"
            @deleteEntry="onDeleteProject"
            @goToDetail="goToProjectDetail"
          ></app-slides>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ion-page>
