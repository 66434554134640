export enum Language {
  HTML = 'HTML',
  CSS = 'CSS',
  JS = 'JavaScript',
  TS = 'TypeScript',
  PYTHON = 'Python',
  JAVA = 'Java',
  PHP = 'PHP',
  RUBY = 'Ruby',
  SQL = 'SQL'
}
